<template lang="pug">
transition(name="fade")
  .navbar-dropdown(v-if="value" v-click-outside="toggleDropdown")
    nuxt-link.navbar-dropdown--item(
      v-for="(item, index) in items"
      :key="index"
      :to="item.link"
    )
      i.fal.navbar-dropdown--icon(v-if="item.icon" :class="item.icon")
      | {{ item.label }}
    .navbar-dropdown--bottom-slot(v-if="$slots.default")
      slot
</template>

<script>
import vClickOutside from 'v-click-outside';
import { breakpointMixin } from '@/mixins/breakpointMixin';

export default {
  name: 'NavbarDropdown',
  directives: {
    clickOutside: vClickOutside.directive,
  },
  mixins: [breakpointMixin],
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    toggleDropdown() {
      this.$emit('input', !this.value);
    },
  },
};
</script>

<style lang="scss">
.navbar-dropdown {
  position: absolute;
  top: 40px;
  right: -30px;
  display: flex;
  flex-direction: column;
  min-width: 240px;
  padding: 10px 0;
  border-radius: $radius;
  box-shadow: 22px 20px 17px rgba($black, .19);
  background: $white;

  &::before, &::after {
    content: '';
    position: absolute;
    right: 45px;
    z-index: 1;
    display: block;
    width: 0;
    height: 0;
    border-width: 0 11px 11px 11px;
    border-style: solid;
    border-color: transparent;
  }

  &::after {
    top: -10px;
    border-bottom-color: $white;
  }

  &::before {
    top: -11px;
    border-bottom-color: $eggshell;
  }

  &--item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 20px;
    color: $blackOlive;
    font-weight: 500;
    font-size: 13px;
    line-height: 38px;
    white-space: nowrap;
    transition: $transition;

    &:hover {
      background: rgba($black, .05);
    }
  }

  &--icon {
    width: 18px;
    margin-right: 15px;
    color: $grey30;
    font-size: 16px;
  }

  &--bottom-slot {
    position: relative;
    display: flex;
    margin: 10px 0 0 0;
    padding: 10px 0 0 0;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 20px;
      left: 20px;
      height: 1px;
      background: $champagne;
    }
  }
  @media screen and (max-width: $breakpoint-md) {
    position: fixed;
    top: 60px;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 0;

    &::before, &::after {
      right: 78px;
    }

    &--backdrop {
      right: 75px;
    }
  }
}
</style>
